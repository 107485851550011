import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import SharedMultiCardContent from '../.././../components/organism/SharedMultiCardcontent/SharedMultiCardContent';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import useStyles from './style';
import usePillarStyles from '../PillarsCarousel/style';
import { useSelector, useDispatch } from 'react-redux';
import { getMultiFilterResultStart } from '../../../containers/common/MultiFilteredLayout/redux/actions';
import { useTheme } from '@material-ui/core/styles';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import Box from '../.././../components/molecules/Box';
import clsx from 'clsx';
import { getUId } from '../../../../utils/utility';
import { get } from 'lodash';

const MultiFilteredLayout = props => {
  const { multiFilterData } = useSelector(state => state.MultiFilterLayoutReducer);
  const classes = useStyles(props);
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const imagePattern = ['single', 'double', 'single', 'single', 'double', 'single', 'single', 'double'];
  const pillarCarouselClasses = usePillarStyles(props);
  const [multifilterCategoryBtns, setMultifilterCategoryBtns] = useState([])
  const [multifilterResult, setMultiFilterResult] = useState(null)
  const multiFilterItemsForLDJson = Array.isArray(multiFilterData) && multiFilterData.map(({ itemUniqueKey, thumbnailImage }) => ({ id: itemUniqueKey, fields: { desktopImage: { value: thumbnailImage ?? { src: {} } } } }))
  const uid = getUId(props)
  const lang = get(props, 'sitecoreContext.language', 'en');
  const dispatch = useDispatch();

  const getRequestData = (offset = 0, limit = 10, filters = []) => {
    return {
      'searchTerm': 'multifilter',
      'language': lang,
      'facetFields': [
      ],
      'ignoreSearchTerm': true,
      'limit': limit,
      'offset': offset,
      filters,
    }
  }


  useEffect(() => {
    const categoriesItem = props.fields.categories
    const filterBtns = categoriesItem.map(item => {
      const { title, contentType } = item.fields || {}
      const contentTypeData = contentType.map(val => ({
        key: val.fields.searchKey?.value ?? '',
        value: val.fields.taxonomyKey?.value ?? '',
      }));
      let truncateTitle = title.value
      return { id: item.id, title: truncateTitle, filters: contentTypeData }
    })
    let filters = filterBtns.flatMap(item => item.filters.map(filter => ({ key: filter.value, value: filter.key })));
    dispatch(getMultiFilterResultStart(getRequestData(0, 11, filters)));
    setMultifilterCategoryBtns(filterBtns)
  }, [])

  useEffect(() => {
    if (Array.isArray(multiFilterData)) {
      const newFilteredResult = [];
      let index = 0;
      imagePattern.forEach(pattern => {
        if (pattern === 'single') {
          newFilteredResult.push([multiFilterData[index]]);
          index++;
        } else if (pattern === 'double') {
          newFilteredResult.push([multiFilterData[index], multiFilterData[index + 1]]);
          index += 2;
        }

      });

      setMultiFilterResult(newFilteredResult.filter(val => val.every(item => item !== undefined)))
    }

  }, [multiFilterData])

  const updatedProps = {
    ...props,
    ...{
      fields: {
        ...props.fields,
        ...{
          carouselItems: multifilterResult,
          multiFilterData: multiFilterItemsForLDJson,
          multifilterCategoryBtns,
          pageName: getPageName(props),
          componentName: getComponentName(props),
        },
      },
    },
  };

  return (
    <Box className={clsx(pillarCarouselClasses.pillarCarousel, classes.multiFiltered)} data-locator={COMPONENT_NAME} uid={uid}>
      {multifilterResult && (
        <SharedMultiCardContent
          {...updatedProps}
          data-locator={COMPONENT_NAME}
        />
      )}
    </Box>
  );
};

MultiFilteredLayout.propTypes = {
  carouselItems: PropTypes.array,
  title: PropTypes.object,
  description: PropTypes.object,
};

MultiFilteredLayout.defaultProps = {
  carouselItems: [],
  title: {},
  description: {},
};


export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(MultiFilteredLayout))
);
